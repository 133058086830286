// Gets holds by user id

import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import { IUserHoldResult } from "./QueryInterfaces";
import { USER_HOLDS_PER_QUERY } from "../utils/Contants";

const UserHoldsDocument = gql`
  query UserHoldsQuery($id: String!, $first: Int, $skip: Int) {
    Hold(
      where: { profile: { id: { _eq: $id } } }
      limit: $first
      offset: $skip
    ) {
      id
      blockNumber
      balance
      asset {
        id
        isLSP7
        lsp4TokenName
        lsp4TokenType
        decimals
        icons(limit: 1) {
          url
        }
        images(limit: 1) {
          url
        }
      }
      token {
        id
        lsp4TokenName
        icons(limit: 1) {
          url
        }
        images(limit: 1) {
          url
        }
      }
      createdBlockNumber
      createdTransactionIndex
      createdTimestamp
      updatedBlockNumber
      updatedTransactionIndex
      updatedTimestamp
    }
  }
`;

const userHoldsClient = new ApolloClient({
  uri: "https://envio.lukso-mainnet.universal.tech/v1/graphql", // Updated API URL
  cache: new InMemoryCache(),
});

export interface GetUserHoldsQueryParams {
  id: string;
  first?: number;
  skip?: number;
}

export async function fetchUserHolds(
  queryParams: GetUserHoldsQueryParams
): Promise<IUserHoldResult[]> {
  const id = queryParams.id.toLowerCase();
  const first = queryParams.first ?? USER_HOLDS_PER_QUERY;
  const skip = queryParams.skip ?? 0;

  const { data, error, loading } = await userHoldsClient.query({
    variables: { id, first, skip },
    query: UserHoldsDocument,
    fetchPolicy: "cache-first",
  });

  return error || loading ? [] : data.Hold ?? [];
}
