import { ApolloClient, InMemoryCache, gql } from "@apollo/client";

const SearchUsersDocument = gql`
  query SearchUserQuery($queryString: String!) {
    Profile(
      where: {
        _or: [
          { name: { _ilike: $queryString } },
          { id: { _ilike: $queryString } }
        ]
      }
      limit: 25
    ) {
      name
      id
      profileImages(limit: 1) {
        url
      }
    }
  }
`;

const searchUserClient = new ApolloClient({
  uri: "https://envio.lukso-mainnet.universal.tech/v1/graphql",
  cache: new InMemoryCache(),
});

export async function fetchUsersByName(queryString: string) {
  const { data, error, loading } = await searchUserClient.query({
    variables: { queryString: `%${queryString.toLowerCase()}%` },
    query: SearchUsersDocument,
    fetchPolicy: "cache-first",
  });

  return data.Profile.map((profile: any) => ({
    name: profile.name,
    id: profile.id,
    profileImages: profile.profileImages || []
  }));
}
