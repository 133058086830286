import { ApolloClient, InMemoryCache, gql, useQuery } from "@apollo/client";

const TokensByTxCountQuery = gql`
  query TokensByTxCount($search: String) {
    tokens(
      first: 25
      orderBy: txCount
      orderDirection: desc
      where: {
        or: [
          { symbol_contains_nocase: $search }
          { name_contains_nocase: $search }
        ]
      }
    ) {
      id
    }
  }
`;

const TopTokensByVolumeQuery = gql`
  query TopTokensByVolume($timestamp: Int!) {
    poolDayDatas(
      first: 1000
      orderBy: volumeUSD
      orderDirection: desc
      where: { date_gt: $timestamp }
    ) {
      pool {
        token0 {
          id
          volumeUSD
        }
        token1 {
          id
          volumeUSD
        }
      }
    }
  }
`;

export function useTokensByTxCountWithString(search: string) {
  const sevenDaysAgo = Math.floor(Date.now() / 1000 / 86400);

  let data, loading, error;

  if (search.length === 0) {
    const result = useQuery(TopTokensByVolumeQuery, {
      variables: { timestamp: sevenDaysAgo - 7 },
      fetchPolicy: "no-cache",
    });
    data = result.data;
    loading = result.loading;
    error = result.error;

    // Process pool data to get top tokens by volume
    const tokenVolumes = new Map<string, number>();

    data?.poolDayDatas?.forEach((poolDay: {
      pool: {
        token0: { id: string, volumeUSD: string },
        token1: { id: string, volumeUSD: string }
      }
    }) => {
      const { token0, token1 } = poolDay.pool;

      // Accumulate volumes for token0
      const currentVolume0 = tokenVolumes.get(token0.id) || 0;
      tokenVolumes.set(token0.id, currentVolume0 + Number(token0.volumeUSD));

      // Accumulate volumes for token1
      const currentVolume1 = tokenVolumes.get(token1.id) || 0;
      tokenVolumes.set(token1.id, currentVolume1 + Number(token1.volumeUSD));
    });

    // Sort tokens by volume and get top 25
    const topTokenIds = Array.from(tokenVolumes.entries())
      .sort((a, b) => b[1] - a[1])
      .slice(0, 25)
      .map(([id]) => id);

    return {
      tokenIds: topTokenIds,
      loading,
      error,
    };
  } else {
    const result = useQuery(TokensByTxCountQuery, {
      variables: { search },
      fetchPolicy: "no-cache",
    });
    data = result.data;
    loading = result.loading;
    error = result.error;

    return {
      tokenIds: data?.tokens?.map((token: { id: string }) => token.id) || [],
      loading,
      error,
    };
  }
}
