export const TRANSACTIONS_PER_QUERY = 20;
export const USER_TRANSACTIONS_PER_QUERY = 20;
export const USER_HOLDS_PER_QUERY = 1000;
export const USER_POOLS_PER_QUERY = 20;

export const HOLD_CARD_COMPUTED_HEIGHT = 85 + 32 + 32;
export const TRANSACTION_CARD_COMPUTED_HEIGHT = 142 + 32 + 32;
export const POOL_CARD_COMPUTED_HEIGHT = 86 + 32 + 32;

export const WEB_THREAD_WIDTH_PERCENT = "40%";
export const MOBILE_THREAD_WIDTH_PERCENT = "92%";
export const WEB_THREAD_WIDTH_PIXEL = 792; // 40% of basic screen 1920px wide full width
export const MOBILE_THREAD_WIDTH_PAD_PX = 10;

export const SOCIAL_REGISTRY_ADDRESS =
  "0xf01103E5a9909Fc0DBe8166dA7085e0285daDDcA";
