// Gets user full profile
// - address (id)
// - name
// - description
// - links
// - profile picture
// - identicon
// - cover picture

import { ApolloClient, InMemoryCache, gql } from "@apollo/client";

const UsersDocument = gql`
  query GetUserProfile($_ids: [String!]) {
    Profile(where: { id: { _in: $_ids } }) {
      id
      name
      profileImages(limit: 1) {
        url
      }
    }
  }
`;

const userProfileClient = new ApolloClient({
  uri: "https://envio.lukso-mainnet.universal.tech/v1/graphql",
  cache: new InMemoryCache(),
});

export async function fetchUsers(ids: string[]) {
  const _ids = ids.map((i) => i.toLowerCase());

  const { data, error } = await userProfileClient.query({
    variables: { _ids },
    query: UsersDocument,
    fetchPolicy: "cache-first",
  });

  // Transform the response to match the old data structure
  return {
    profiles: data.Profile.map((profile: any) => ({
      id: profile.id,
      name: profile.name,
      profileImages: profile.profileImages || []
    }))
  };
}
