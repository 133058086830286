import { ApolloClient, InMemoryCache, gql } from "@apollo/client";

const UserIsFollowedDocument = gql`
  query QueryFollowers($connectedUser: Profile_bool_exp!, $userToCheck: Profile_bool_exp!) {
    Follow(
      where: {
        follower: $connectedUser
        followee: $userToCheck
      }
    ) {
      id
    }
  }
`;

export const userIsFollowedClient = new ApolloClient({
  uri: "https://envio.lukso-mainnet.universal.tech/v1/graphql",
  cache: new InMemoryCache(),
});

export async function userIsFollowed(
  _connectedUser: string,
  _userToCheck: string
): Promise<boolean> {
  if (!_connectedUser || !_userToCheck) return false;

  const connectedUser = _connectedUser.toLowerCase();
  const userToCheck = _userToCheck.toLowerCase();

  const { data, error } = await userIsFollowedClient.query({
    variables: {
      connectedUser: { id: { _eq: connectedUser } },
      userToCheck: { id: { _eq: userToCheck } }
    },
    query: UserIsFollowedDocument,
    fetchPolicy: "no-cache",
  });

  if (error) {
    throw new Error("Failed to fetch followers");
  }

  return data.Follow.length !== 0;
}
