import { createClientUPProvider } from '@lukso/up-provider';
import { useCallback, useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';

export interface UPProviderAccounts {
  userAccount: string | undefined;
  gridOwnerAccount: string | undefined;
  isConnected: boolean;
}

export function useUPProvider(): UPProviderAccounts {
  const { chainId } = useWeb3React();
  const [accounts, setAccounts] = useState<string[]>([]);
  const [isConnected, setIsConnected] = useState(false);
  const [provider, setProvider] = useState<any>(null);

  // Initialize provider in useEffect to handle errors
  useEffect(() => {
    try {
      const up = createClientUPProvider();
      setProvider(up);
    } catch (error) {
      console.error('Failed to create UP Provider:', error);
    }
  }, []);

  const updateConnected = useCallback((accounts: string[], chainId: number) => {
    setIsConnected(
      accounts.length > 0 &&
      accounts[0] !== '0x' &&
      accounts[0] !== accounts[1] &&
      chainId === 42
    );
  }, []);

  useEffect(() => {
    // Only proceed if provider exists
    if (!provider) return;

    async function init() {
      try {
        const _accounts = await provider.request({ method: 'eth_accounts' });
        setAccounts(_accounts);
        const _chainId = await provider.request({ method: 'eth_chainId' });
        updateConnected(_accounts, parseInt(_chainId, 16));
      } catch (error) {
        console.error('Failed to initialize UP Provider:', error);
      }
    }

    init();

    provider.on('accountsChanged', (_accounts: string[]) => {
      setAccounts(_accounts);
      updateConnected(_accounts, chainId ?? 0);
    });

    provider.on('chainChanged', (_chainId: string) => {
      updateConnected(accounts, parseInt(_chainId, 16));
    });

    return () => {
      provider.removeAllListeners('accountsChanged');
      provider.removeAllListeners('chainChanged');
    };
  }, [chainId, updateConnected, provider]);

  return {
    userAccount: accounts[0],
    gridOwnerAccount: accounts[1],
    isConnected
  };
}
