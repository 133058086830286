import { ApolloClient, InMemoryCache, gql } from "@apollo/client";

const TokenLogoDocument = gql`
  query TokenLogoQuery($ids: [String!]) {
    Asset(where: { id: { _in: $ids } }) {
      id
      icons(limit: 1) {
        url
      }
    }
  }
`;

const tokenLogoClient = new ApolloClient({
  uri: "https://envio.lukso-mainnet.universal.tech/v1/graphql",
  cache: new InMemoryCache(),
});

export async function fetchTokensLogos(ids: string[]) {
  const { data, error, loading } = await tokenLogoClient.query({
    variables: { ids: ids.map(id => id.toLowerCase()) },
    query: TokenLogoDocument,
    fetchPolicy: "cache-first",
  });

  // Transform the response to match the old data structure
  return {
    assets: data.Asset.map((asset: any) => ({
      id: asset.id,
      icons: asset.icons || []
    }))
  };
}
