import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import { FollowersData } from "./QueryInterfaces";

const FollowersDocument = gql`
  query QueryFollowers($userId: Profile_bool_exp!, $limit: Int, $offset: Int) {
    Follow(
      where: { followee: $userId }
      limit: $limit
      offset: $offset
    ) {
      follower {
        id
      }
    }
  }
`;

const FolloweesDocument = gql`
  query QueryFollowees($userId: Profile_bool_exp!, $limit: Int, $offset: Int) {
    Follow(
      where: { follower: $userId }
      limit: $limit
      offset: $offset
    ) {
      followee {
        id
      }
    }
  }
`;

const followsClient = new ApolloClient({
  uri: "https://envio.lukso-mainnet.universal.tech/v1/graphql",
  cache: new InMemoryCache(),
});

async function fetchFollowers(userId: string) {
  const followers = [];
  let tmpFollowers = [];

  const limit = 100;
  let offset = 0;

  let { data, error } = await followsClient.query({
    variables: {
      userId: { id: { _eq: userId } },
      limit,
      offset
    },
    query: FollowersDocument,
    fetchPolicy: "no-cache",
  });

  if (error) {
    throw new Error("Failed to fetch followers");
  }

  tmpFollowers = data;
  if (!tmpFollowers?.Follow) {
    return [];
  }

  followers.push(...tmpFollowers.Follow);

  while (tmpFollowers?.Follow?.length === 100) {
    offset = followers.length;
    let result = await followsClient.query({
      variables: {
        userId: { id: { _eq: userId } },
        limit,
        offset
      },
      query: FollowersDocument,
      fetchPolicy: "no-cache",
    });

    if (result.error) {
      throw new Error("Failed to fetch followers");
    }

    tmpFollowers = result.data;
    followers.push(...tmpFollowers.Follow);
  }

  return followers;
}

async function fetchFollowees(userId: string) {
  const followees = [];
  let tmpFollowees = [];

  const limit = 100;
  let offset = 0;

  let { data, error } = await followsClient.query({
    variables: {
      userId: { id: { _eq: userId } },
      limit,
      offset
    },
    query: FolloweesDocument,
    fetchPolicy: "no-cache",
  });

  if (error) {
    throw new Error("Failed to fetch followees");
  }

  tmpFollowees = data;
  if (!tmpFollowees?.Follow) {
    return [];
  }

  followees.push(...tmpFollowees.Follow);

  while (tmpFollowees?.Follow?.length === 100) {
    offset = followees.length;
    let result = await followsClient.query({
      variables: {
        userId: { id: { _eq: userId } },
        limit,
        offset
      },
      query: FolloweesDocument,
      fetchPolicy: "no-cache",
    });

    if (result.error) {
      throw new Error("Failed to fetch followees");
    }

    tmpFollowees = result.data;
    followees.push(...tmpFollowees.Follow);
  }

  return followees;
}

export async function fetchFollows(_userId: string): Promise<FollowersData> {
  if (!_userId) return { user: { followers: [], followings: [] } };

  const userId = _userId.toLowerCase();
  const followers = await fetchFollowers(userId);
  const followees = await fetchFollowees(userId);

  return {
    user: {
      followers: followers.map(f => ({
        follower: {
          address: f.follower.id,
          isUniversalProfile: true // Assuming all profiles are Universal Profiles
        }
      })),
      followings: followees.map(f => ({
        followee: {
          address: f.followee.id,
          isUniversalProfile: true // Assuming all profiles are Universal Profiles
        }
      }))
    }
  };
}
