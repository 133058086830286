// Gets user full profile
// - address (id)
// - name
// - description
// - links
// - profile picture
// - identicon
// - cover picture

import { ApolloClient, InMemoryCache, gql } from "@apollo/client";

const UserProfileDocument = gql`
  query GetUserProfile($_id: String!) {
    Profile(where: { id: { _eq: $_id } }) {
      description
      id
      links {
        title
        url
      }
      name
      profileImages(limit: 1) {
        url
      }
      backgroundImages(limit: 1) {
        url
      }
    }
  }
`;

const userProfileClient = new ApolloClient({
  uri: "https://envio.lukso-mainnet.universal.tech/v1/graphql",
  cache: new InMemoryCache(),
});

export async function fetchUserProfile(id: string) {
  const _id = id.toLowerCase();
  const { data, error, loading } = await userProfileClient.query({
    variables: { _id },
    query: UserProfileDocument,
    fetchPolicy: "cache-first",
  });

  // Transform the response to match the old data structure
  return {
    profile: data.Profile?.[0] || null
  };
}
