import { ApolloClient, InMemoryCache, gql, useQuery } from "@apollo/client";

const tokenDocument = gql`
  query GetTokensInfos($ids: [String!]) {
    Asset(where: { id: { _in: $ids } }) {
      id
      icons(limit: 1) {
        url
      }
      lsp4TokenName
      lsp4TokenSymbol
      decimals
    }
  }
`;

const upNameClient = new ApolloClient({
  uri: "https://envio.lukso-mainnet.universal.tech/v1/graphql",
  cache: new InMemoryCache(),
});

interface TokenQueryResult {
  assets: {
    icons: { url: string }[];
    lsp4TokenName: string;
    lsp4TokenSymbol: string;
    id: string;
    decimals: number;
    holders: { balance: string }[];
  }[];
}

export function useTokensInfos(ids: string[]) {
  const options = { variables: { ids: ids.map(id => id.toLowerCase()) } };

  const query = useQuery<TokenQueryResult>(tokenDocument, {
    ...options,
    client: upNameClient,
  });

  // Transform the response to match the old data structure
  if (query.data?.Asset) {
    return {
      ...query,
      data: {
        assets: query.data.Asset.map(asset => ({
          id: asset.id,
          icons: asset.icons || [],
          lsp4TokenName: asset.lsp4TokenName,
          lsp4TokenSymbol: asset.lsp4TokenSymbol,
          decimals: asset.decimals,
          holders: [] // Default empty array since we don't fetch holders in this query
        }))
      }
    };
  }

  return query;
}
