import {
  TRADES,
  TOKENS,
  USERS,
  FOLLOWS,
  PORTFOLIOS,
  POOLS,
  SocialUserInterface,
} from "./MockData";

import { TradeMockInterface } from "./MockData";
import { TradeDisplayProps } from "../components/SocialThread/SocialTradeThread";
import { TradeTypeEnum } from "./TradeEnum";
import { GetReadableDeltaFromNow } from "./ReadableTime";
import {
  fetchLatestTransactions,
  GetTradesQueryParams,
} from "../queries/TransactionsQuery";
import { fetchUserProfile } from "../queries/UserProfileQuery";
import lyxLogo from "assets/svg/lukso.svg";

import { fetchUserHolds } from "../queries/UserHoldsQuery";
import tokenDefault from "assets/svg/token-default.svg";

import {
  IBurnResult,
  IMintResult,
  IPositionResult,
  ISwapResult,
  ITokenResult,
  ITransactionResult,
  IUserHoldResult,
  IUserResult,
} from "../queries/QueryInterfaces";
import { fetchUserTransactions } from "../queries/UserTransactionsQuery";
import { IUserLinkResult } from "../queries/QueryInterfaces";
import { fetchUsers } from "../queries/UsersQuery";
import { fetchTokensLogos } from "../queries/TokenLogoQuery";
import makeBlockie from "ethereum-blockies-base64";
import { fetchUserPools } from "../queries/UserPoolsQuery";
import numeral from "numeral";
import { fetchUsersByName } from "../queries/SearchUsersQuery";
import { UserSearchResultProps } from "components/NavBar/SearchBar";
import {
  fetchTransactionsSinceTimeStamp,
  GetTradesSinceTimestampQueryParams,
} from "../queries/TransactionsSinceTimestampQuery";
import { fetchFollows } from "../queries/GetFollowersQuery";
import { fetchFolloweesTransactions, GetFolloweesTradesQueryParams } from "../queries/GetFolloweesTradesQuery";
import { address } from "nft/components/explore/Cells/Cells.css";
import { FormattedFollowItem } from "../Profile";
import { fetchFolloweesTransactionsSinceTimeStamp, GetFolloweesTradesSinceTimestampQueryParams } from "../queries/TransactionsFolloweesSinceTimeStampQuery";

import { generateBlockie } from "components/UniconV2/generateUniconV2";
import getImagesURLs from "./getUsersURLs";
import { userIsFollowed } from "../queries/UserIsFollowedQuery";

// Add this new interface to track swap paths
interface SwapPathSummary {
  origin: string;
  initialToken: {
    id: string;
    symbol: string;
    amount: number;
  };
  finalToken: {
    id: string;
    symbol: string;
    amount: number;
  };
}

// Add this new helper function
const aggregateSwapPaths = function(swaps: ISwapResult[]): SwapPathSummary[] {
  // First, organize swaps by origin
  const swapsByOrigin: { [key: string]: ISwapResult[] } = {};

  for (const swap of swaps) {
    if (!swapsByOrigin[swap.origin]) {
      swapsByOrigin[swap.origin] = [];
    }
    swapsByOrigin[swap.origin].push(swap);
  }

  const result: SwapPathSummary[] = [];

  // Process each origin's swaps
  for (const [origin, originSwaps] of Object.entries(swapsByOrigin)) {
    // Find all unique tokens involved
    const tokens = new Set<string>();
    originSwaps.forEach(swap => {
      tokens.add(swap.token0.id);
      tokens.add(swap.token1.id);
    });

    // Find tokens that are only inputs (never outputs) and only outputs (never inputs)
    const onlyInputs = new Set<string>(tokens);
    const onlyOutputs = new Set<string>(tokens);

    originSwaps.forEach(swap => {
      const inputToken = swap.amount0 > 0 ? swap.token0.id : swap.token1.id;
      const outputToken = swap.amount0 > 0 ? swap.token1.id : swap.token0.id;
      onlyOutputs.delete(inputToken);
      onlyInputs.delete(outputToken);
    });

    // Calculate total amounts for initial and final tokens
    let totalInitialAmount = 0;
    let totalFinalAmount = 0;
    let initialToken = null;
    let finalToken = null;

    originSwaps.forEach(swap => {
      const fromToken = swap.amount0 > 0 ? swap.token0 : swap.token1;
      const toToken = swap.amount0 > 0 ? swap.token1 : swap.token0;
      const fromAmount = Math.abs(swap.amount0 > 0 ? swap.amount0 : swap.amount1);
      const toAmount = Math.abs(swap.amount0 > 0 ? swap.amount1 : swap.amount0);

      if (onlyInputs.has(fromToken.id)) {
        totalInitialAmount += fromAmount;
        initialToken = fromToken;
      }
      if (onlyOutputs.has(toToken.id)) {
        totalFinalAmount += toAmount;
        finalToken = toToken;
      }
    });

    if (initialToken && finalToken) {
      result.push({
        origin,
        initialToken: {
          id: initialToken.id,
          symbol: initialToken.symbol,
          amount: totalInitialAmount
        },
        finalToken: {
          id: finalToken.id,
          symbol: finalToken.symbol,
          amount: totalFinalAmount
        }
      });
    }
  }

  return result;
};

export const FilterFollowedUsersTrades = function (
  trades: TradeMockInterface[],
  followedUsersAddresses: string[]
): TradeMockInterface[] {
  return trades.filter((trade) =>
    followedUsersAddresses.includes(trade.userAddr)
  );
};

export const GetUser = function (
  userAddress: string
): Promise<SocialUserInterface> {
  return new Promise<SocialUserInterface>(async (resolve, reject) => {
    const user = await fetchUserProfile(userAddress);
    const userName =
      user.profile === null ||
      user.profile.name?.length === 0 ||
      user.profile.name === null
        ? "anon account"
        : user.profile.name;

    const identicon = makeBlockie(userAddress);

    const profilePicUrls =
      user.profile?.profileImages?.map((image) =>
        image.url.startsWith("ipfs://")
          ? `https://api.universalprofile.cloud/ipfs/${image.url.slice(7)}`
          : image.url
      ) ?? [];
    const validProfilePicUrls = await getImagesURLs(
      profilePicUrls,
      generateBlockie(userAddress, 100)
    );
    const profilePic =
      validProfilePicUrls[0] ?? generateBlockie(userAddress, 100);

    const backgroundPicUrls =
      user.profile?.backgroundImages?.map((image) =>
        image.url.startsWith("ipfs://")
          ? `https://api.universalprofile.cloud/ipfs/${image.url.slice(7)}`
          : image.url
      ) ?? [];
    const validBackgroundPicUrls = await getImagesURLs(backgroundPicUrls, "");
    const backgroundPic = validBackgroundPicUrls[0] ?? "";

    resolve({
      name: userName,
      address: userAddress,
      tags:
        user.profile?.links.map((link: IUserLinkResult) => {
          return { display: link.title, target: link.url };
        }) ?? [],
      description: user.profile?.description ?? "No description",
      picture: {
        main: profilePic,
        cover: backgroundPic,
        identicon: identicon,
      },
    });
  });
};

export const GetUsersByName = async function (
  query: string
): Promise<UserSearchResultProps[]> {
  return new Promise(async (resolve, reject) => {
    const users = await fetchUsersByName(query);

    const userSearchResults = await Promise.all(
      users.map(async (user: IUserResult) => {
        const profilePicUrls =
          user?.profileImages?.map((image) =>
            image.url.startsWith("ipfs://")
              ? `https://api.universalprofile.cloud/ipfs/${image.url.slice(7)}`
              : image.url
          ) ?? [];
        const validProfilePicUrls = await getImagesURLs(
          profilePicUrls,
          generateBlockie(user.id, 100)
        );
        const profilePic =
          validProfilePicUrls[0] ?? generateBlockie(user.id, 100);

        return {
          name: user.name,
          id: user.id,
          profilePic: profilePic,
          identicon: makeBlockie(user.id),
        };
      })
    );

    resolve(userSearchResults);
  });
};

export const UserIsFollowed = function(connectedUser: string, userToCheck: string) : Promise<boolean> {
  return new Promise(async (resolve, reject) => {
    const isFollowed = await userIsFollowed(connectedUser, userToCheck);
    resolve(isFollowed);
  })
}

export const GetNullUsers = function(userIds: string[]): Promise<FormattedFollowItem[]> {
  return new Promise(async (resolve, reject) => {
    resolve(
      userIds.map((userId: string, index: number) => {
        return {
          name: "anon account",
          address: userId,
          profilePictureSrc: generateBlockie(userId, 100),
          identiconSrc: makeBlockie(userId)//generateBlockie(user.id, 100),
        };
      })
    );
  })
};

export const GetUsers = function (
  userIds: string[]
): Promise<FormattedFollowItem[]> {
  return new Promise(async (resolve, reject) => {
    const users = await fetchUsers(userIds);

    const profilePictureSrcs = users.profiles.map((user: IUserResult) =>
      user?.profileImages[0]?.url.startsWith("ipfs://")
        ? `https://api.universalprofile.cloud/ipfs/${user?.profileImages[0]?.url.slice(
            7
          )}`
        : user?.profileImages[0]?.url ?? generateBlockie(user?.id, 100)//""
    );

    const validProfilePictureSrcs = await getImagesURLs(
      profilePictureSrcs,
      generateBlockie(userIds[0], 100)
    );

    resolve(
      users.profiles.map((user: IUserResult, index: number) => {
        return {
          name: getUserName(user),
          address: user.id,
          profilePictureSrc: profilePictureSrcs[index],//validProfilePictureSrcs[index],
          identiconSrc: makeBlockie(user.id)//generateBlockie(user.id, 100),
        };
      })
    );
  });
};

export const GetToken = function (tokenAddress: string) {
  return TOKENS.filter((token) => token.address == tokenAddress)[0];
};

const getUserName = function (user: IUserResult): string {
  return user === null ||
    user === undefined ||
    user?.name?.length === 0 ||
    user?.name === null
    ? "anon account"
    : user.name;
};

const getProfilePicSrc = function (user: IUserResult, userId: string): string {
  return user?.profileImages[0]?.url.startsWith("ipfs://")
    ? `https://api.universalprofile.cloud/ipfs/${user?.profileImages[0]?.url.slice(
        7
      )}`
    : user?.profileImages[0]?.url ?? generateBlockie(userId, 100);
};

const mapTranscationToProp = function (
  user: IUserResult,
  transaction: IMintResult | IBurnResult | ISwapResult,
  token0Src: string,
  token1Src: string,
  transactionType: TradeTypeEnum,
  timestamp: number
) {
  const fromToken =
    transaction.amount0 > 0 ? transaction.token0 : transaction.token1;
  const toToken =
    transaction.amount0 > 0 ? transaction.token1 : transaction.token0;
  const fromAmount = Math.abs(
    transaction.amount0 > 0 ? transaction.amount0 : transaction.amount1
  );
  const toAmount = Math.abs(
    transaction.amount0 > 0 ? transaction.amount1 : transaction.amount0
  );

  return {
    userName: getUserName(user),
    userProfilePic: getProfilePicSrc(user, user?.id ?? transaction.origin),
    userAddress: transaction.origin,
    fromAddress: fromToken.id,
    fromName: fromToken.symbol,
    fromQuantity: numeral(Math.round(fromAmount * 100) / 100).format("0,0.00"),
    fromSrc: transaction.amount0 > 0 ? token0Src : token1Src,
    toAddress: toToken.id,
    toName: toToken.symbol,
    toQuantity: numeral(Math.round(toAmount * 100) / 100).format("0,0.00"),
    toSrc: transaction.amount0 > 0 ? token1Src : token0Src,
    type: transactionType,
    time: timestamp,
    timestamp: timestamp,
  };
};

const getTokensSrcFromTransactions = async function (
  transactions: ITransactionResult[]
): Promise<{ [key: string]: string }> {
  const tokenIds = transactions.reduce(
    (acc: string[], current: ITransactionResult) => {
      acc.push(
        ...current.burns.map((b) => [b.token0.id, b.token1.id]).flat(),
        ...current.mints.map((m) => [m.token0.id, m.token1.id]).flat(),
        ...current.swaps.map((s) => [s.token0.id, s.token1.id]).flat()
      );
      return [...new Set(acc)];
    },
    []
  );

  const tokens = (await fetchTokensLogos(tokenIds)).assets.reduce(
    (acc: { [key: string]: string }, current: ITokenResult) => {
      let url: string;

      if (current.id === "0x2db41674f2b882889e5e1bd09a3f3613952bc472")
        // wLYX1
        url = lyxLogo;
      else if (current.icons.length === 0) url = tokenDefault;
      else if (current.icons[0].url.startsWith("ipfs"))
        url = `https://api.universalprofile.cloud/ipfs/${current.icons[0].url.slice(
          7
        )}`;
      else url = current.icons[0].url;

      acc[current.id] = url;
      return acc;
    },
    {}
  );

  return tokens;
};

export const GetTrades = function (
  queryParams?: GetTradesQueryParams | GetTradesSinceTimestampQueryParams
): Promise<TradeDisplayProps[]> {
  return new Promise<TradeDisplayProps[]>(async (resolve, reject) => {
    // Fetches transactions
    let transactions: ITransactionResult[];
    if (queryParams === undefined || queryParams.timestamp === undefined)
      transactions = await fetchLatestTransactions(
        queryParams as GetTradesQueryParams
      );
    else
      transactions = await fetchTransactionsSinceTimeStamp(
        queryParams as GetTradesSinceTimestampQueryParams
      );

    // Fetches transactions related users
    const userIds = transactions.reduce(
      (acc: string[], current: ITransactionResult) => {
        const mintOrigins = current.mints.map((m) => m.origin);
        const burnOrigins = current.burns.map((b) => b.origin);
        const swapOrigins = current.swaps.map((s) => s.origin);
        acc.push(...mintOrigins, ...burnOrigins, ...swapOrigins);

        return [...new Set(acc)];
      },
      []
    );
    const users = (await fetchUsers(userIds)).profiles.reduce(
      (acc: { [key: string]: IUserResult }, current: IUserResult) => {
        acc[current.id] = current;

        return acc;
      },
      {}
    );

    const tokens = await getTokensSrcFromTransactions(transactions);

    const result: TradeDisplayProps[] = [];

    for (let t of transactions) {
      for (let mint of t.mints) {
        result.push(
          mapTranscationToProp(
            users[mint.origin],
            mint,
            tokens[mint.token0.id],
            tokens[mint.token1.id],
            TradeTypeEnum.Deposit,
            t.timestamp
          )
        );
      }
      for (let burn of t.burns) {
        result.push(
          mapTranscationToProp(
            users[burn.origin],
            burn,
            tokens[burn.token0.id],
            tokens[burn.token1.id],
            TradeTypeEnum.Withdrawal,
            t.timestamp
          )
        );
      }
      const swapPaths = aggregateSwapPaths(t.swaps);
      for (let path of swapPaths) {
        result.push({
          userName: getUserName(users[path.origin]),
          userProfilePic: getProfilePicSrc(users[path.origin], path.origin),
          userAddress: path.origin,
          fromAddress: path.initialToken.id,
          fromName: path.initialToken.symbol,
          fromQuantity: numeral(Math.round(path.initialToken.amount * 100) / 100).format("0,0.00"),
          fromSrc: tokens[path.initialToken.id],
          toAddress: path.finalToken.id,
          toName: path.finalToken.symbol,
          toQuantity: numeral(Math.round(path.finalToken.amount * 100) / 100).format("0,0.00"),
          toSrc: tokens[path.finalToken.id],
          type: TradeTypeEnum.Swap,
          time: t.timestamp,
          timestamp: t.timestamp,
        });
      }
    }

    resolve(result);
  });
};

export const GetUserTrades = function (
  userAddress: string,
  first?: number,
  skip?: number
): Promise<TradeDisplayProps[]> {
  return new Promise<TradeDisplayProps[]>(async (resolve, reject) => {
    const transactions = await fetchUserTransactions({
      id: userAddress,
      first: first,
      skip: skip,
    });
    const result: TradeDisplayProps[] = [];
    if (transactions.length === 0) resolve(result);

    const user = await fetchUserProfile(userAddress);

    const tokens = await getTokensSrcFromTransactions(transactions);

    for (let t of transactions) {
      for (let mint of t.mints) {
        result.push(
          mapTranscationToProp(
            user.profile,
            mint,
            tokens[mint.token0.id],
            tokens[mint.token1.id],
            TradeTypeEnum.Deposit,
            t.timestamp
          )
        );
      }
      for (let burn of t.burns) {
        result.push(
          mapTranscationToProp(
            user.profile,
            burn,
            tokens[burn.token0.id],
            tokens[burn.token1.id],
            TradeTypeEnum.Withdrawal,
            t.timestamp
          )
        );
      }
      const swapPaths = aggregateSwapPaths(t.swaps);
      for (let path of swapPaths) {
        result.push({
          userName: getUserName(user.profile),
          userProfilePic: getProfilePicSrc(user.profile, path.origin),
          userAddress: path.origin,
          fromAddress: path.initialToken.id,
          fromName: path.initialToken.symbol,
          fromQuantity: numeral(Math.round(path.initialToken.amount * 100) / 100).format("0,0.00"),
          fromSrc: tokens[path.initialToken.id],
          toAddress: path.finalToken.id,
          toName: path.finalToken.symbol,
          toQuantity: numeral(Math.round(path.finalToken.amount * 100) / 100).format("0,0.00"),
          toSrc: tokens[path.finalToken.id],
          type: TradeTypeEnum.Swap,
          time: t.timestamp,
          timestamp: t.timestamp,
        });
      }
    }

    resolve(result);
  });
};

export const GetFollowedTrades = async function (
  queryParams: GetFolloweesTradesQueryParams | GetFolloweesTradesSinceTimestampQueryParams,
): Promise<TradeDisplayProps[]> {
  return new Promise<TradeDisplayProps[]>(async (resolve, reject) => {
    let transactions: ITransactionResult[];
    if (queryParams.timestamp === undefined)
      transactions = await fetchFolloweesTransactions(
        queryParams as GetFolloweesTradesQueryParams
      );
    else
      transactions = await fetchFolloweesTransactionsSinceTimeStamp(
        queryParams as GetFolloweesTradesSinceTimestampQueryParams
      );

    // const transactions = await fetchFolloweesTransactions({
    //   followees: followedUsers,
    //   first: first,
    //   skip: skip,
    // });

    // Fetches transactions related users
    const userIds = transactions.reduce(
      (acc: string[], current: ITransactionResult) => {
        const mintOrigins = current.mints.map((m) => m.origin);
        const burnOrigins = current.burns.map((b) => b.origin);
        const swapOrigins = current.swaps.map((s) => s.origin);
        acc.push(...mintOrigins, ...burnOrigins, ...swapOrigins);

        return [...new Set(acc)];
      },
      []
    );
    const users = (await fetchUsers(userIds)).profiles.reduce(
      (acc: { [key: string]: IUserResult }, current: IUserResult) => {
        acc[current.id] = current;

        return acc;
      },
      {}
    );

    const tokens = await getTokensSrcFromTransactions(transactions);

    const result: TradeDisplayProps[] = [];

    for (let t of transactions) {
      for (let mint of t.mints) {
        result.push(
          mapTranscationToProp(
            users[mint.origin],
            mint,
            tokens[mint.token0.id],
            tokens[mint.token1.id],
            TradeTypeEnum.Deposit,
            t.timestamp
          )
        );
      }
      for (let burn of t.burns) {
        result.push(
          mapTranscationToProp(
            users[burn.origin],
            burn,
            tokens[burn.token0.id],
            tokens[burn.token1.id],
            TradeTypeEnum.Withdrawal,
            t.timestamp
          )
        );
      }
      const swapPaths = aggregateSwapPaths(t.swaps);
      for (let path of swapPaths) {
        result.push({
          userName: getUserName(users[path.origin]),
          userProfilePic: getProfilePicSrc(users[path.origin], path.origin),
          userAddress: path.origin,
          fromAddress: path.initialToken.id,
          fromName: path.initialToken.symbol,
          fromQuantity: numeral(Math.round(path.initialToken.amount * 100) / 100).format("0,0.00"),
          fromSrc: tokens[path.initialToken.id],
          toAddress: path.finalToken.id,
          toName: path.finalToken.symbol,
          toQuantity: numeral(Math.round(path.finalToken.amount * 100) / 100).format("0,0.00"),
          toSrc: tokens[path.finalToken.id],
          type: TradeTypeEnum.Swap,
          time: t.timestamp,
          timestamp: t.timestamp,
        });
      }
    }

    resolve(result);
    //resolve([]); //trades.filter(trade => followedUsers.includes(trade.userAddress)));
  });
};

export const GetFollowedUsers = function (
  userAddress: string
): Promise<string[]> {
  return new Promise<string[]>(async (resolve, reject) => {
    const data = await fetchFollows(userAddress);
    resolve(
      (
        data?.user?.followings?.map((entry: { followee: { address: string } }) =>
          entry?.followee?.address?.toLowerCase()
        ) ?? []
      ).filter((item: string) => item)
    );
  });
};

export const GetFollowerUsers = function (
  userAddress: string
): Promise<string[]> {
  return new Promise<string[]>(async (resolve, reject) => {
    const data = await fetchFollows(userAddress);
    resolve(
      (
        data?.user?.followers?.map((entry: { follower: { address: string } }) =>
          entry?.follower?.address?.toLowerCase()
        ) ?? []
      ).filter((item: string) => item)
    );
  });
};

export const AddFollower = function (
  followedAddress: string,
  followerAddress: string
): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    FOLLOWS.push({
      followedAddress: followedAddress,
      followerAddress: followerAddress,
    });
    resolve();
  });
};

export const RemoveFollower = function (
  followedAddress: string,
  followerAddress: string
): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    const follow = FOLLOWS.filter(
      (f) =>
        f.followedAddress === followedAddress &&
        f.followerAddress === followerAddress
    )[0];
    const index = FOLLOWS.indexOf(follow);
    FOLLOWS.splice(index, 1);
    resolve();
  });
};

export interface UserPortfolio {
  tokenIconSrc: string;
  tokenImageSrc: string;
  tokenName: string;
  tokenQuantity: number;
  tokenSymbol: string;
  tokenAddress: string;
  isLSP7: boolean;
  tokenType: number;
  decimals: number;
}

// retrieves address after the first # in the id
function extractAddress(id: string) {
  const parts = id.split("#");
  if (parts.length > 1) {
    return parts[1];
  }
  return "";
}

export const GetUserPortfolio = function (
  userAddress: string,
  first?: number,
  skip?: number
): Promise<UserPortfolio[]> {
  return new Promise<UserPortfolio[]>(async (resolve, reject) => {
    const holds = await fetchUserHolds({
      id: userAddress,
      first: first,
      skip: skip,
    });

    resolve(
      holds.map((hold: IUserHoldResult) => {
        let tokenIconSrc = tokenDefault;
        let tokenImageSrc = tokenDefault;
        let tokenName = "";
        let tokenSymbol = "";
        let tokenAddress = "";
        let decimals = 0;

        // Extract token address (always use the part after the hyphen if it exists)
        if (hold.id.includes("-")) {
          tokenAddress = hold.id.split("-")[1].toLowerCase();
        } else if (hold.id.includes("#")) {
          tokenAddress = hold.id.split("#")[1].toLowerCase();
        } else {
          tokenAddress = hold.id.toLowerCase();
        }

        // Handle LSP7/LSP8 assets
        if (hold.asset) {
          if (hold.asset.icons?.[0]?.url) {
            tokenIconSrc = hold.asset.icons[0].url.startsWith("ipfs://")
              ? `https://api.universalprofile.cloud/ipfs/${hold.asset.icons[0].url.slice(7)}`
              : hold.asset.icons[0].url;
          }

          if (hold.asset.images?.[0]?.url) {
            tokenImageSrc = hold.asset.images[0].url.startsWith("ipfs://")
              ? `https://api.universalprofile.cloud/ipfs/${hold.asset.images[0].url.slice(7)}`
              : hold.asset.images[0].url;
          }

          tokenName = hold.asset.lsp4TokenName || "";
          tokenSymbol = hold.asset.lsp4TokenSymbol || "";
          decimals = hold.asset.decimals || 0;
        }
        // Handle regular tokens
        else if (hold.token) {
          if (hold.token.icons?.[0]?.url) {
            tokenIconSrc = hold.token.icons[0].url.startsWith("ipfs://")
              ? `https://api.universalprofile.cloud/ipfs/${hold.token.icons[0].url.slice(7)}`
              : hold.token.icons[0].url;
          }

          if (hold.token.images?.[0]?.url) {
            tokenImageSrc = hold.token.images[0].url.startsWith("ipfs://")
              ? `https://api.universalprofile.cloud/ipfs/${hold.token.images[0].url.slice(7)}`
              : hold.token.images[0].url;
          }

          tokenName = hold.token.lsp4TokenName || "";
          tokenSymbol = hold.token.lsp4TokenSymbol || "";
        }

        return {
          tokenIconSrc,
          tokenImageSrc,
          tokenName,
          tokenSymbol,
          tokenAddress,
          tokenQuantity: parseFloat(hold.balance),
          isLSP7: hold.asset?.isLSP7 || false,
          tokenType: hold.asset?.lsp4TokenType || 0,
          decimals,
        };
      })
    );
  });
};

export interface UserPoolsInterface {
  token1Src: string;
  token1Name: string;
  token1Symbol: string;
  token1Address: string;
  token2Src: string;
  token2Name: string;
  token2Symbol: string;
  token2Address: string;
  rate: number;
  inRange: boolean;
  liquidity: number;
}

export const GetUserPools = function (
  userAddress: string,
  first?: number,
  skip?: number
): Promise<UserPoolsInterface[]> {
  return new Promise<UserPoolsInterface[]>(async (resolve, reject) => {
    const userPools = await fetchUserPools({
      id: userAddress,
      first: first,
      skip: skip,
    });

    const tokenIds = userPools.reduce(
      (acc: string[], current: IPositionResult) => {
        acc.push(current.token0.id, current.token1.id);

        return [...new Set(acc)];
      },
      []
    );

    const tokens = (await fetchTokensLogos(tokenIds)).assets.reduce(
      (acc: { [key: string]: string }, current: ITokenResult) => {
        let url: string;

        if (current.id === "0x2db41674f2b882889e5e1bd09a3f3613952bc472")
          // wLYX1
          url = lyxLogo;
        else if (current.icons.length === 0) url = tokenDefault;
        else if (current.icons[0].url.startsWith("ipfs"))
          url = `https://api.universalprofile.cloud/ipfs/${current.icons[0].url.slice(
            7
          )}`;
        else url = current.icons[0].url;

        acc[current.id] = url;
        return acc;
      },
      {}
    );

    resolve(
      userPools.map((position: IPositionResult) => {
        const liquidityToken0 =
          Number(position.depositedToken0) *
          Number(position.token0.derivedETH) *
          4;
        const liquidityToken1 =
          Number(position.depositedToken1) *
          Number(position.token1.derivedETH) *
          4;
        const liquidity = numeral(liquidityToken0 + liquidityToken1).format(
          "0,0.00"
        );

        // USER AS EXAMPLE : 0xe3b0bc9abc0e0ca7a5a7b17850223ba215039944

        // check if price is within range
        const tickLower = +position.tickLower.tickIdx;
        const tickUpper = +position.tickUpper.tickIdx;
        const tickCurrent = position.pool.tick;

        const below =
          position && typeof tickLower === "number"
            ? tickCurrent < tickLower
            : undefined;
        const above =
          position && typeof tickUpper === "number"
            ? tickCurrent >= tickUpper
            : undefined;
        const inRange: boolean =
          typeof below === "boolean" && typeof above === "boolean"
            ? !below && !above
            : false;

        return {
          token1Src: tokens[position.token0.id],
          token1Name: position.token0.name,
          token1Symbol: position.token0.symbol,
          token1Address: position.token0.id,
          token2Src: tokens[position.token1.id],
          token2Name: position.token1.name,
          token2Symbol: position.token1.symbol,
          token2Address: position.token1.id,
          rate: position.pool.feeTier / 10_000,
          inRange: inRange,
          liquidity: liquidity,
        };
      })
    );
  });
};
